body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout-menu {
  overflow-y: auto;
  overflow-x: hidden;
}

.gh-select-store {
  background-color: #F5F5F9;
  color: #697A8D;
  font-weight: 400;
}

.gh-select-store:hover {
  background-color: #F5F5F9;
  color: #697A8D;
  font-weight: 400;
}

.gh-yellow {
  background-color: #FDA329;
  color: #fff;
}

.gh-yellow:hover {
  background-color: #FDA329;
  color: #fff;
}

.no-transform {
  transform: none !important;
}

.no-transform:hover {
  transform: none !important;
}

div.gh-line-break {
  border-bottom:4px solid #F0A747;
}

div.gh-profile {
  padding: 16px;
}

div.gh-profile button.dropdown-toggle {
  padding: 6px 4px;
  width: 100%;
  background-color: #F5F5F9;
}

.gh-dashboard-values {
  color: #1E1B39 !important;
}

.gh-coming-soon {
  background: rgb(225, 225, 225, 0.4) !important;
}

.gh-coming-soon-banner {
  height: 40px;
  position: absolute;
  top: 34px;
  right: -44px;
  color: white;
  padding: 8px 30px;
  font-weight: 400;
  transform: rotate(45deg);
  z-index: 1;
  width: 200px;
  text-align: center;
  background-color: #FDA328;
}

.gh-min-height-170 {
  min-height: 170px;
}

.gh-product-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #4B5563;
}

.gh-value-box {
  margin-right: 20px;
}
.gh-value-box:last-child {
  margin-right: 0;
}
.gh-h-px-20 {
  height: 20px !important;
  display: flex;
  align-items: center;
  width: auto !important;
}

.gh-applied-filter-item {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;

  padding: 6px 8px 6px 8px;
  border-radius: 20px ;

  color: #4B5563;
  background: #F5F5F9;
  border: 1px solid #F0F2F4
}

.sc-cBYhjr.hZUxNm {
  overflow: visible;
  white-space: normal;
  text-overflow: unset;
}
.sc-eEPDDI.gMeyEf {
  overflow-y: scroll;
  max-height: 500px;
}

.gh-order-summary-title {
  color: #1E1B39;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.gh-order-summary-hint {
  color: #4B5563;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.gh-order-summary-item .label{
  color: #1E1B39;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.gh-order-summary-item .value{
  color: #4B5563;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.gh-order-summary-item .value.grand-total{
  color: #1E1B39;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.gh-order-summary-table tr th {
  color: #1E1B39;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 10px 0;
}

.gh-order-summary-table tr td {
  color: #4B5563;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 0;
}

.gh-datatable-search {
  border: 1px solid #E5E5EF;
  padding: 10px 8px 10px 8px;
  gap: 8px;
  border-radius: 8px;
  outline: 0;
  width: 25%;
  margin-right: -16px;
}

.gh-appearance-none {
  appearance: none !important;
}


.gh_react_select__control.gh_react_select__control--is-focused {
  border-color: #121872 !important;
  color: #697a8d;
  background-color: #fff;
  border-color: rgba(249, 249, 255, 0.54);
  outline: 0;
  box-shadow: 0 0 0.25rem 0.05rem rgba(105, 108, 255, 0.1);
}

.gh_react_select__indicator-separator {
  display: none;
}

.gh_react_select__single-value {
  color: #697a8d !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.scroll-to-bottom {
  position: fixed;
  height: 70px;
  width: 70px;
  bottom: 10px;
  right: 10px;
  transition: all 0.5s ease-in-out;
}

.scroll-to-bottom-icon {
  display: inline;
  margin: auto;
  height: 50px;
  width: 50px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
